import { NextPage } from "next";
import React from "react";

const NotFoundPage: NextPage & {
  useLayout?: boolean;
  layoutTitle?: string;
} = () => {
  return (
    <p className="text-xl w-full text-center">
      This page doesn&apos;t seem to exist.
    </p>
  );
};

NotFoundPage.useLayout = true;
NotFoundPage.layoutTitle = "404 - Page not found";

export default NotFoundPage;
